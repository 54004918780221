import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

function useLSFirst<TData>(
  key: string,
  query: any,
  props: any
): {
  loading: boolean
  data: TData | null
  [key: string]: any
} {
  const [localData, setLocalData] = useState<TData | null>(() => {
    // Retrieve initial data from local storage
    const storedData = localStorage.getItem(key)
    return storedData ? JSON.parse(storedData) : null
  })

  const { loading, data: serverData, ...rest } = useQuery(query, props)

  useEffect(() => {
    if (serverData) {
      // Update local storage when new data is fetched
      localStorage.setItem(key, JSON.stringify(serverData))
      // Sync local data with the server data
      setLocalData(serverData)
    }
  }, [serverData, key])

  // Return loading state and localData as a fallback for `data`
  return {
    loading,
    data: localData || serverData, // localData first, then serverData
    ...rest, // Pass through any additional properties (e.g., error)
  }
}

export const clearLS = (keys: string[]) => {
  keys.forEach((key) => localStorage.removeItem(key))
}

export default useLSFirst
